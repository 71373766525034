<template lang="pug">
	BannerGaleriaTemplate#Galeria(:ativo.sync="ativo", :length="gallery.length")
		ul.main
			li(v-for="item, index in gallery" :class="{'ativo' : index == ativo}")
				img(:src="item.image" :alt="item.description")
				p {{item.name}}
		template(v-slot:nav="{ toggleBanner }")
			ul.nav
				li(v-for="item, index in galeria", @click="toggleBanner(index)")
					img(:src="item.image")
</template>

<script>
import { props } from '@/mixins/component'
import BannerGaleriaTemplate from '@components/BannerGaleriaTemplate/BannerGaleriaTemplate'

export default {
	name: 'galeria',
	props,
	components: {
		BannerGaleriaTemplate
	},
	computed: {
		navItems(){
			if(this.contents.content.length - 2 > this.ativo)
				return this.contents.content.slice(this.ativo+1)
			else
				return this.contents.content.slice(this.contents.content.length - 2)
		}
	},
	data() {
		return {
			ativo: 0,
		}
	},
	computed: {
		galeria() {
			if (this.gallery.length - 2 > this.ativo)
				return this.gallery.slice(this.ativo+1)
			else
				return this.gallery.slice(this.gallery.length - 2)
		},
		gallery() {
			return this.contents.content.map(item => ({
				...item,
				image: `${process.env.VUE_APP_FILE_API_ROUTE}/medias/media/${item.image}`
			}))
		}
	},
}
</script>

<style lang="stylus" scoped src="./Galeria.styl">

</style>
